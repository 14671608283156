header {
  background-image: linear-gradient(to bottom,
      black,
      var(--color-bg),
      transparent),
    url("../../assets/bg-texture.png");
  background-repeat: no-repeat;
  background-size: contain;
  height: 30vh;
  padding-top: 2rem;
  overflow: scroll;
}

.wrapper2 {
  display: grid;
  place-items: center;
}

.typing-demo {
  width: 28ch;
  animation: typing 6s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 1.5em;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.header__containerpc {
  text-align: center;
  height: 100vh;
}

.h {
  color: red;
}




.h1 {
  font-family: "Courier New", Courier, monospace;
  color: antiquewhite;
}

@media screen and (max-width: 600px) {
  header {

    padding-top: 6rem;
  }
}