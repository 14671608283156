nav {
  background: rgba(0, 0, 0, 0.3);
  width: max-content;
  display: block;
  padding: 0.7rem 1, 7rem;
  z-index: 2000;
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  bottom: 2rem;
  display: flex;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
}

.sizeUp {
  font-size: x-large;
}

@keyframes circle {
  from {
    transform: rotate(-75deg);
  }

  to {
    transform: rotate(75deg);
  }

}

nav a {
  background: transparent;
  padding: 0.9rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 1.4rem;
}

nav a.active {
  background: var(--color-primary);
  color: var(--color-bg);
  animation: circle 2s linear infinite alternate;
}