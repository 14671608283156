.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
  margin-bottom: 4rem;
}

.portfolio__items {
  backdrop-filter: blur(30px);

  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid var(--color-primary-variant);
  transition: var(--transition);
  box-shadow: var(--color-primary-variant) 0px 4px 19px 0px;
}

.portfolio__items:hover {
  border-color: var(--color-primary-variant);

  background: transparent;
}

.portfolio__item-image {
  display: flex;
  flex-direction: column;
  border-radius: 1.5rem;
  overflow: hidden;
  margin-bottom: 0.8rem;
  object-fit: contain;
  aspect-ratio: 4/3;
}





.time {
  display: flex;
  color: var(--color-primary);
  margin: auto auto;
  align-items: center;
  font-size: 50px;
}
.portfolio__item-image:hover {
  overflow: hidden;
  margin-bottom: 0.6rem;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
  align-items: flex-start;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.3rem;
  margin-top: 0.6rem;
}

/* note start */

.muzieknootjes2 {
  display: block;
  margin: auto;
  /* left: 33rem; */
  position: absolute;
  width: 50%;
  min-width: 300px;
  height: 200px;
  border: 0px solid #000;
}

.muzieknootjes {
  display: block;
  margin: auto;
  left: 33rem;
  position: absolute;
  width: 50%;
  min-width: 300px;
  height: 200px;
  border: 0px solid #000;
}

.noot-1,
.noot-2,
.noot-3,
.noot-4 {
  position: absolute;
  animation: notes 2s infinite linear;
  font-size: 35px;
  opacity: 0;
}

.noot-1 {
  top: 60px;
  left: 0;
  animation-delay: 0.5s;
}

.noot-2 {
  top: 30px;
  left: 30%;
  animation-delay: 1s;
}

.noot-3 {
  top: 90px;
  left: 60%;
  animation-delay: 1.5s;
}

.noot-4 {
  top: 40px;
  left: 90%;
  animation-delay: 2s;
}

@keyframes notes {
  0% {
    transform: scale(1) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: scale(1.5) translate(50%, -50%);
  }
  80% {
    opacity: 0;
    transform: scale(1.5) translate(100%, -100%);
  }
  100% {
    transform: scale(1.5) translate(100%, -100%);
    opacity: 0;
  }
}

/* note end */

.client__link {
  margin-bottom: 5rem;
}

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }

  .muzieknootjes {
    display: block;
    margin: auto;
    left: 10rem;
    position: absolute;
    width: 50%;
    min-width: 300px;
    height: 200px;
    border: 0px solid #000;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .muzieknootjes {
    display: block;
    margin: auto;
    left: 3rem;
    position: absolute;
    width: 50%;
    min-width: 300px;
    height: 200px;
    border: 0px solid #000;
  }
}
