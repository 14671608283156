.askme_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: auto;
}

.askme_form_container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(7px);
    padding: 1rem;
    border-radius: 1rem;

    /* Red shadow with 30% opacity */
}

.askme_form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    width: 100%;
    margin-top: 1rem;

}

.askme-control-text,
.askme-control-input {
    width: 100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 2px solid var(--color-primary-variant);
    resize: none;
    color: var(--color-white);
}



button {
    padding: 1rem;
    background-color: var(--color-primary);
    color: #fff;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: var(--color-primary-variant);
}

@media screen and (max-width: 600px) {
    .askme_form_container {
        width: var(--container-width-sm);
    }

    .askme_form {
        padding: 0 15px;
    }


}